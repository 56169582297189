@import "./globalVariables.scss";

.printe-template-preview {
  width: calc(570px/2 - 16px);
  overflow: auto;
  white-space: pre-line;
  border: 2px solid $blackColor;
  color: $blackColor;
  padding: 8px;
  margin: auto;
}

.printe-template-preview> img{
  width: 100%;
}

.preview-image{
  width: 100%;
  height: 80px;
  background-color: $blackColor;
  color: $whiteColor;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-top: 55px;
}
