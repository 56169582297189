@import "./globalVariables.scss";

.landing-image-list {
  margin: 0 16px 16px 0;
  padding: 10px;
}

.landing-image-list-content {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  position: relative;
}

.landing-section-item{
  width: 100%;
  height: 100%;
  display: flex;
  color: $whiteColor !important;
  margin-bottom: 20px;
}

.landing-section-item> img{
  height: 80%;
  margin-right: 1rem;
}

.imageInfo {
  font-size: 14px;
  color: $tipsColor;
}

.image-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px 0;
}

.landing-item-preview{
  //width: 90%;
  display: flex;
  // gap: 20px;
}

.landing-item-preview .landing-image-list-content{
  flex-direction: column;
  height: 100%;;
}

.landing-section-preview {
  height: 90vh;
  overflow-y: scroll;
  padding: 10px;
}

.landing-section-item-box{
  // margin-right: 10px;
  min-width: 150px;
  background-color: $grayColor ;
  padding: 5px;
  max-height: 100%;
}

.edit-section-item {
  margin-top: 10px;
  margin-bottom: 20px;
}

.item-box{
  // margin-right: 10px;
  min-width: 150px;
  background-color: $grayColor ;
  padding: 5px;
  max-height: 100%;
}

.landing-image-list-edit{
  justify-content: center;
}

.landing-image-list-edit .landing-image-list-content{
  flex-direction: column;
  position: relative;
}

.landing-image-list-content-background{
  padding: 5px;
  
}

.landing-image-list-content-foreground{
  border-radius: 20px;
  padding: 5px;
}

.gulu-framed-grid-tile-img {
  border-radius: 10px;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.gulu-framed-grid-tile-img> .gulu-img {
  width: 100%;
  height: 100%;
  // min-width: 100px;
  // min-height: 100px;
  object-fit: cover;
  position: absolute;
}

.landing-section-preview-edit-button{
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.2s;
}

.landing-image-list:hover {
  .landing-section-preview-edit-button{
    opacity: 1;
  }
}

.landing-item-list-preview-main{
  background-color: rgb(34, 34, 34);
  padding: 10px;
  min-height: 150px;
}

.landing-item-list-preview-main .image-card-background{
  //background-color: rgb(68, 68, 68) !important;
  color: $whiteColor !important;
}

.landing-section {
  padding: 10px 10px;
  background-color: $grayColor ;
  flex: 1;
}

.landing-image-list-edit {
  background-color: $grayColor !important;
  color: $whiteColor !important;
}

.landing-item-preview .landing-image-list{
  min-width: 200px;
  height: 90%;
}

// .landing-item-list-preview-main{
//   min-height: 20vh;
//   min-width: 20vw;
//   max-width: 50vw;
//   width: fit-content;
// }

.landing-item-preview{
  width: 100%;
  overflow-x: auto;
}

.landing-section-item-box{
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  // flex: 1;
}

.gulu-production-grid-tile-horizontal{
  border-radius: 15px;
  background-color: $whiteColor;
  overflow: hidden;
  color: $blackColor;
  flex: 1;
  height: 100%;
}

.gulu-banner {
  position: relative;
  width: 200px;
  height: 89px;
  overflow: hidden;
}

.gulu-banner> .gulu-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-item-list-preview .gulu-banner {
  min-width: 350px;
}

.gulu-production-grid-tile-horizontal-image {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.gulu-production-grid-tile-horizontal-image> .gulu-img {
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  position: absolute;
}

.grid-tile-promotion-tag {
  position: absolute;
  bottom: 5px;
  left: 0;
  height: 10%;
}

.grid-tile-promotion-tag img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gulu-production-grid-tile-horizontal-promotion-tag> * {
  height: 100%;
}

.gulu-production-grid-tile-horizontal-description{
  padding: 10px;
}

.gulu-production-grid-tile-horizontal-description > * {
  margin: 10px 0;
}

.gulu-ecoupon-grid-tile {
  border-radius: 15px;
  background-color: $whiteColor;
  overflow: hidden;
  color: $blackColor;
  flex: 1;
  padding: 10px;
}

.gulu-ecoupon-grid-tile-description > * {
  margin: 10px 0;
}

.gulu-ecoupon-grid-tile-button {
  background-color: #ff5000;
  border-radius: 55px;
  padding: 10px;
  color: $whiteColor;
  display: flex;
  justify-content: center;
  font-weight: 700;
}

.gulu-ecoupon-grid-tile-img {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
}

.gulu-ecoupon-grid-tile-img> .gulu-img {
  width: 100%; 
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 15px;
  position: absolute;
}

.MuiDialogContent-root .landing-image-list-content .gulu-ecoupon-grid-tile{
  min-width: 300px;
}

.landing-section .landing-section-item .gulu-grid-tile{
  max-width: 150px;
}

.item-box .gulu-grid-tile {
  max-width: 150px;
}

.landing-section .landing-section-item .gulu-grid-tile-img> .imageIcon {
  max-width: 150px;
}

.MuiDialogContent-root .landing-image-list-content .gulu-grid-tile{
  width: 20%;
}

.gulu-grid-tile-img {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  border-radius: 20px;
}

.gulu-grid-tile-img> .gulu-img {
  width: 100%; 
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  border-radius: 15px;
  position: absolute;
}

.gulu-grid-tile-description {
  padding: 5px;
}

.gulu-card-grid-tile{
  background-color: $whiteColor;
  overflow: hidden;
  color: $blackColor;
  flex: 1;
}

.gulu-card-grid-tile-img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
}

.gulu-card-grid-tile-img> .gulu-img {
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100px;
  object-fit: cover;
  position: absolute;
}

.gulu-framed-grid-tile {
  width: 150px;
}

.gulu-ecoupon-grid-tile {
  width: 150px;
}

.landing-section .landing-section-item .gulu-framed-grid-tile {
  max-width: 150px;
}

.landing-section .landing-section-item .gulu-card-grid-tile {
  max-width: 150px;
}

.landing-section .landing-section-item .gulu-production-grid-tile-horizontal {
  max-width: 150px;
}

.item-box .gulu-production-grid-tile-horizontal {
  max-width: 150px;
}

.landing-section .landing-section-item .gulu-ecoupon-grid-tile {
  max-width: 150px;
}

.gulu-card-grid-tile-description{
  padding: 5px;
}

.gulu-framed-grid-tile-description {
  padding: 5px;
}

.landing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $whiteColor;
}

.landing-header-left {
  display: flex;
  align-items: center;
}

.landing-header-left-icon {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  margin-right: 5px;
}

.landing-header-left-icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.recommend-by-location-shop-tile {
  width: 150px;
  height: 150px;
}

.recommend-by-location-shop-tile-img-wrapper {
  background-color: #9b624a;
  padding-bottom: 70%;
  position: relative;
}

.recommend-by-location-shop-tile-img {
  position: absolute;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.recommend-by-location-shop-tile-img> .gulu-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // position: absolute;
}

.large-img {
  min-width: 200px;
}

.landing-image-list-icon-bar {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;

  .delete-icon:hover {
    cursor: pointer;
  }
}

.landing-image-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: 20px;
}

.landing-image-edit-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.landing-section-item-list-box {
  width: 100%;
  background-color: $grayColor;
  padding: 5px;
  max-height: 100%;
}

.text-preview {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}