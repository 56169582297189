@import './globalVariables.scss';

$primaryColor: rgba(25, 0, 255, 0.1);
$hoverColor: thistle;
$boxShadow: 0px 2px 1px -1px rgba(25, 0, 255, 0.5), 0px 1px 1px 0px rgba(25, 0, 255, 0.14), 0px 1px 3px 0px rgba(25, 0, 255, 0.12);

%common-item {
  border-radius: 10px;
  box-shadow: $boxShadow;
  background-color: $primaryColor;
  padding: 5px;
  font-family: monospace;
  height: 15px;
  width: fit-content;
  padding: 8px 10px;
  margin: 5px 5px 0 0;
  cursor: pointer;
  justify-content: center;
  display: flex;
  
  &:hover {
    background-color: $hoverColor;
  }
  
  &.no-hover {
    cursor: auto;
    
    &:hover {
      background-color: $primaryColor;
    }
  }
  
  .sub-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .delete-button--clear {
    opacity: 0;
  }
  
  &:hover .delete-button--clear {
    opacity: 1;
  }
}

.horizontal-droppable-content {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 5px;

  .horizontal-item {
    @extend %common-item;
    flex-direction: row;
  }
}

.vertical-droppable-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 5px;

  .vertical-item {
    @extend %common-item;
    flex-direction: column;
  }
}