@import "./globalVariables.scss";

.input {
  margin-right: 8px !important;
  width: 100% !important;
}

.restaurant-district-add-btn {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  position: initial !important;
  margin-left: 3% !important;
}

.restaurant-district-form {
  display: block;
}

.district-input {
  margin-right: 8px !important;
  width: 100% !important;
}

.district-btn{
  margin-bottom: 30px !important;
  margin-left: 8px !important;
  height: 40px;
  width: 25% !important;
}

.district-id-input{
  margin-right: 8px !important;
  width: 65% !important;
}

.restaurant-district-list-card{
  margin-bottom: 1em;
  width: auto;
}

// .restaurant-district-list-cancelBtn{
//   float: right;
// }

.restaurant-district-list-card-simple-list{
  height: 100%; 
  display: flex;
  flex-direction: column;
  border: 1px solid $lightGrayColor;
  box-shadow: 5px 5px $lightGrayColor;
}

.restaurant-district-list-card-simple-list-span{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.restaurant-district-list-card-simple-list-span-tcname{
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.restaurant-district-list-card-simple-list-span-btn{
  margin-top: 5px;
}

.restaurant-district-list-card-simple-list-btn{
  float: right;
  border-radius: 4px;
  background-color: $subItemColor;
  border: none;
  color: $whiteColor;
  padding: 5px 22px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.restaurant-district-list-card-simple-list-text{
  font-size: 12px;
}

.restaurant-district-card{
  margin-bottom: 1em;
  width: 100%;
}

.restaurant-district-card-wrapper{
  padding: 16px;
}

.restaurant-district-get-wrapper{
  display: initial;
  width: 100%;
}

.restaurant-district-get-title{
  display: flex;
  flex-direction: column;
}

.restaurant-district-get-input{
  display: flex;
}

.restaurant-district-get-customize{
  margin-left: auto;
  margin-top: auto;
}

.cancel-btn{
  padding: 6px 16px !important;
  margin-left: 8px !important;
}

@media screen and (min-width: 1275px) {
  .input {
    width: 30% !important;
  }

  .selected-input {
    width: 30% !important;
  }

  .district-id-input{
    width: 60% !important;
  }
  
  .district-btn {
    width: 32% !important;
  }

  .restaurant-district-add-btn {
    margin-left: 16px !important;
  }

  .district-id-inner{
    width: 49%;
  }
}

@media screen and (min-width: 960px) and (max-width: 1276px) {
  .input {
    width: auto !important;
  }

  .selected-input {
    width: 30% !important;
  }

  .district-id-input{
    width: 50% !important;
  }

  .district-btn {
    width: 30% !important;
  }

  .restaurant-district-add-btn {
    margin-left: 16px !important;
  }

  .district-id-inner{
    width: 49%;
  }
}
