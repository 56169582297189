@import "./globalVariables.scss";

.merchant-user-check-box-wrapper{
  display: inline-flex;
  flex-direction: column;
  width: 30%;
  margin: 16px 0;
  .title{
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  .content{
    display: flex;
    flex-direction: row;
    .text{
      display: inline;
      margin: 0 16px 0 0;
    }
  }
}