@import './globalVariables.scss';

.product-create-hearder {
  display: flex;
  justify-content: space-between;
}

.product-create-btn {
  display: flex;
  flex-direction: row-reverse;
  background-color: $whiteColor;
  margin-bottom: 4px;
  padding: 0px 16px;
}

.stepper-wrapper {
  width: 100%;
  padding: 10px 0px;
  max-height: 80px;
  display: flex;
  // justify-content: space-around;
  align-items: center;
  .header {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    .draft-name {
      color: rgba(18, 18, 18, 0.38);
    }
  }
}

.step-content-outer-wrapper {
  width: 100%;
  height: calc(100% - 90px);
  .step-content-wrapper {
    height: calc(100% - 37px);
    display: flex;
    background-color: $whiteColor;
    padding-left: 16px;
    & > .list-wrapper {
      padding: 16px;
    }
    &.category-step-content {
      flex-wrap: wrap;
    }
    .split-pane-left {
      .list-wrapper {
        &.redeem-group-list-wrapper,
        &.product-list-wrapper,
        &.product-lot-list-wrapper {
          @include display-flex(flex-start, flex-start, column);
          .array-list-wrapper {
            flex-shrink: 0;
          }
          .step-content-content {
            flex-grow: 1;
          }
        }
      }
      .category-list-wrapper {
        @include display-flex(flex-start, flex-start, column);
        height: 100%;
        .sub-title-wrapper {
          @include display-flex(flex-start, center);
          flex-shrink: 0;
        }
        .product-category-list-card {
          width: calc(100% - 20px);
          padding: 10px;
          flex-grow: 1;
        }
      }
    }
    .split-pane-right {
      .step-content-content-wrapper {
        height: calc(100% - 32px);
        overflow: auto;
        padding: 16px;
      }
    }
    .split-pane-left,
    .split-pane-right {
      height: 590px;
      padding: 5px 0px;
      .list-wrapper {
        height: 100%;
        margin-bottom: 0px;
      }
      .step-content-content {
        width: calc(100% - 20px);
        padding: 10px;
        overflow: auto;
        background: $whiteColor;
        // &.redeem-group-content {
        //   width: calc(100% - 48px) !important;
        //   padding: 24px;
        // }
        .top-tool-bar {
          padding-top: 0px;
        }
        .category-filter {
          flex-wrap: initial;
          min-height: initial;
          margin-top: initial;
        }
      }
    }
  }
  .buttons {
    // height: 37px;
    button {
      margin-left: 16px;
    }
  }
}

.step-button {
  cursor: pointer;
}

// .step-content-content-wrapper {
//   width: calc(100% - 32px) !important;
//   overflow: auto;
//   padding: 16px;
// }

.flex-right {
  // flex: 6;
  flex-grow: 1;
}

.step-content-content-btn {
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn-margin {
    margin-left: 4px;
  }
}

// .step-content-content-padding{
// padding-top: 16px;
// }

.list-card-wrapper {
  .RaFilterForm-form-43 {
    margin-top: 0px;
  }
}

.step-content-show-box {
  margin: 16px !important;
}

.draft-name-wrapper {
  display: flex;
}

.draft-name-input {
  margin-left: 8px !important;
  width: 100%;
}

.card-padding {
  padding: 16px !important;
}

.cat-create-card-padding {
  padding-left: 16px !important;
  padding-bottom: 16px;
}

.mezzofy-coupon-ID-input {
  margin: 16px 0px !important;
  width: 100%;
}

.image-card-background {
  background-color: $aliceblueColor !important;
}

.image-list {
  margin-bottom: 16px;
  padding: 16px;
}

.image-list-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageInfo {
  font-size: 14px;
  color: $tipsColor;
}

.helperText {
  color: rebeccapurple;
}

.image-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px 0;
}

.product-image-list-wrapper {
  width: 100%;
}

.array-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.product-array-list-wrapper {
  width: 100%;
}

.btn-display {
  display: block;
}

.btn-margin {
  margin: 16px !important;
}

.deleteIcon {
  float: right;
}

.product-create-product-list {
  flex-direction: column !important;
}

.button-on-top {
  z-index: 1 !important;
}

.table-head {
  padding: 0px 16px;
  background-color: $aliceblueColor;
  margin-top: auto;
}

.tab-background {
  background-color: $whiteColor;
}

.sub-item-line {
  border-bottom: 2px solid $blackColor;
  margin-left: 16px;
}

.attachment-item {
  width: fit-content;
  padding: 5px 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(25, 0, 255, 0.5), 0px 1px 1px 0px rgba(25, 0, 255, 0.14), 0px 1px 3px 0px rgba(25, 0, 255, 0.12);
  background-color: rgba(25, 0, 255, 0.1);
  // cursor: pointer;
}

.code-title {
  display: inline;
  color: $appBarColor;
}

.card-border {
  border: 2px solid;
  padding: 16px;
}

.user-group-buttons {
  display: flex;
  justify-content: flex-end;
}

.selected-product {
  background-color: lightyellow !important;
}

.product-create-list-item {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2), 0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  flex-wrap: wrap;
  &:hover {
    background-color: lightyellow;
  }
  &.no-hover {
    &:hover {
      background-color: transparent;
    }
  }
  .code {
    margin-right: 20px;
    font-size: 12px;
    color: $grayColor;
    .type {
      color: lightsalmon;
    }
  }
  .content {
    width: 100%;
    .top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .name {
        font-size: 24px;
        font-weight: bold;
      }
      .existing {
        color: rgba(25, 0, 255, 0.5);
        font-size: 24px;
        margin-left: 2px;
      }
      .delete-button {
        margin-left: auto;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: end;
      gap: 10px;
      padding-top: 10px;

      .price {
        font-size: 24px;
        margin-left: auto;
        color: deeppink;
        flex: 1;
      }
    }

    .bottom {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .sub-item {
        font-family: monospace;
        width: fit-content;
        padding: 5px 10px;
        margin-right: 10px;
        display: flex;
        border-radius: 10px;
        box-shadow: 0px 2px 1px -1px rgba(25, 0, 255, 0.5), 0px 1px 1px 0px rgba(25, 0, 255, 0.14), 0px 1px 3px 0px rgba(25, 0, 255, 0.12);
        background-color: rgba(25, 0, 255, 0.1);
        cursor: pointer;
        &:hover {
          background-color: thistle;
          color: $blackColor;
          .sub-product-name,
          .sub-item-name {
            color: $blackColor !important;
          }
        }
        &.no-hover {
          cursor: auto;
          &:hover {
            background-color: rgba(25, 0, 255, 0.1);
            color: $blackColor;
            .sub-product-name,
            .sub-item-name {
              color: $blackColor !important;
            }
          }
        }
        .code {
          margin-right: 20px;
          font-size: 12px;
          color: $grayColor;
        }
        .sub-centent {
          display: flex;
          justify-content: space-between;
          .sub-content-item {
            display: flex;
            .sub-item-name {
              color: $blackColor;
            }
            .multiply-icon {
              margin: 0px 5px;
            }
            .quantity {
              font-weight: bold;
            }
          }
          .button-on-top {
            display: flex;
            align-items: center;
            padding: 0px 5px;
            color: $subItemColor;
            &:hover {
              color: $lightBlueColor;
            }
          }
        }
      }
    }
  }
}

.footer-buttons {
  padding-bottom: 10px;
  display: flex;
  .publish-button {
    margin-left: auto;
    color: $whiteColor;
    background-color: $otherBtnColor;
    &:hover {
      background-color: $otherBtnHoverColor;
    }
  }
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: $grayColor;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: auto !important;
  display: flex;
  align-items: center;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: $aliceblueColor;
  display: flex;
  z-index: 99999;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: visible;
}

.hidden {
  display: none;
}
