@import './globalVariables.scss';

body {
  background-color: #fafafa;
  width: 100%;
  min-width: 960px;
  min-height: calc(100vh - 48px);
}

.content-wrapper {
  display: flex;
  flex-shrink: 1;
  height: calc(100% - 48px);
  width: 100%;
}

.card-content-wrapper {
  width: calc(100% - 32px) !important;
  overflow: auto !important;
}

.login-background {
  background-color: $themeColor;
  width: 100% !important;
  height: 100vh !important;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-center-card {
  margin: 150px auto;
}

.content-main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.content-main-with-menu {
  width: calc(100% - 250px);
  height: 100%;
  overflow-y: auto;
}

.vertical-menu {
  width: 250px;
  height: 100%;
  background-color: $whiteColor;
  overflow-y: scroll;
}

.vertical-menu-sub-menu-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.vertical-menu-sub-menu-title {
  display: flex;
  text-align: center;
  align-items: center;
}

.vertical-menu-sub-menu-icon {
  padding-right: 8px;
}

.vertical-sub-menu {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.vertical-menu div {
  background-color: $whiteColor;
  color: $blackColor;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 12px;
  text-decoration: none;
  cursor: pointer;
}

.vertical-menu div:hover {
  background-color: $lightGrayColor;
}

.vertical-menu div:active {
  background-color: $appBarColor;
  color: $whiteColor;
}

.list-wrapper {
  width: 100%;
  height: 100%;
  .stepper {
    padding: 5px;
    flex: 4;
    background-color: initial;
  }
}

.list-title-wrapper {
  display: flex;
  justify-content: space-between;
}

.sub-tab-wrapper {
  margin: -32px;
}

.sub-tab-wrapper-margin-32 {
  margin: 32px;
}

.cursor {
  cursor: pointer;
}

.upload-image {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.upload-image-video {
  position: relative;
}

.upload-image-video-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 70px);
  background: transparent;
  opacity: 0.2;
}

.Resizer {
  background: $blackColor;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer.horizontal {
  height: 5px;
  margin: 10px 0;
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(18, 18, 18, 0.5);
  border-bottom: 5px solid rgba(18, 18, 18, 0.5);
}

.Resizer.vertical {
  width: 5px;
  margin: 0 10px;
  cursor: col-resize;
  background-color: '$blackColor';
  flex-shrink: 0;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(18, 18, 18, 0.5);
  border-right: 5px solid rgba(18, 18, 18, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.noText {
  text-align: center;
}

.imageIcon {
  width: 100% !important;
  font-size: 200px !important;
}

.pictures {
  margin-bottom: 1em;
  padding: 1em;
  display: inline-flex;
  flex-direction: column;
  width: 10%;
  background-color: $imageWrapperBackground !important;
}

.pictures-mid-size {
  margin-bottom: 1em;
  padding: 1em;
  max-height: 380px;
  display: inline-flex;
  flex-direction: column;
  width: 20%;
  background-color: $imageWrapperBackground !important;
}

.picture {
  margin-bottom: 1em;
  padding: 1em;
  display: inline-flex;
  flex-direction: column;
  width: 90%;
  background-color: $imageWrapperBackground !important;
}

.card-img-top {
  max-width: 100%;
  max-height: 250px;
  object-fit: contain;
}

.card-wrapper {
  padding: 32px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto !important;
}

.card {
  margin: 16px 0;
}

.font-color-black {
  color: $blackColor !important;
}

.font-color-blue {
  display: inline;
  color: #2196f3 !important;
}

.list-content {
  margin-top: 2px;
  display: flex;
}

.list-label-tab {
  padding: 2px;
  margin: 0 4px;
  display: inline;
  background-color: #2196f3;
  color: $whiteColor;
  height: fit-content;
  min-width: 20px;
  text-align: center;
}

.list-edit-btn {
  padding: 6px !important;
}

.edit-wrapper {
  display: flex;
  align-items: stretch;
}

.edit-tab-wrapper {
  padding: 0px !important;
}

.edit-footer-wrapper {
  background-color: #f5f5f5;
  // margin: -32px;
  // margin-top: 16px;
  min-height: 64px;
  display: flex;
  position: relative;
  align-items: center;
  // position: absolute;
  // top: auto;
  // left: 0;
  // right: 0;
  // bottom : 0;
  // width: calc(100% + 40px);
  &.padding-16 {
    padding-left: 16px;
  }
}

.margin-left-16 {
  margin-left: 16px !important;
}

.margin-right-16 {
  margin-right: 16px !important;
}

.margin-16-4 {
  margin: 16px 4px !important;
}

.drawer-margin-left {
  margin-left: 5% !important;
}

.special-button {
  margin-right: 16px !important;
  color: $whiteColor !important;
  background-color: $otherBtnColor !important;
  &:hover {
    background-color: $otherBtnHoverColor !important;
  }
  &:disabled {
    color: rgba(18, 18, 18, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(18, 18, 18, 0.12) !important;
    cursor: default !important;
    pointer-events: none !important;
  }
}

.special-button-margin-left {
  margin-left: auto !important;
}

.display-none {
  display: none !important;
}

.customize-btn {
  display: flex;
  justify-content: flex-end;
}

.delete-icon {
  display: flex;
  justify-content: flex-end;
}

.delete-icon-row {
  display: inline;
  float: right;
}

.overflow {
  overflow-y: auto !important;
}

.flex-display-wrapper {
  display: flex;
  gap: 20px;
}

.edit-display-wrapper {
  display: inline;
}

.check-box-md {
  font-size: 20px;
}

.add-btn {
  display: block;
}

.image-enlarge-icon {
  display: flex;
  flex-direction: row-reverse;
}

.image {
  display: inline;
  width: 200px;
  margin-right: 8px;
}

.image-show {
  width: calc(200px - 32px);
  margin: 0 32px;
}

.image-card-title {
  font-size: 20px;
  font-weight: bold;
}

.image-muti {
  margin-right: 8px;
}

.tips-message {
  display: flex;
  align-items: center;
  color: $tipsColor;
  font-size: 12px;
  text-align: left;
}

.error-message {
  display: flex;
  align-items: center;
  color: $errorColor;
  font-size: 12px;
  text-align: left;
}

.error-message-icon {
  margin-right: 4px !important;
}

.error-large {
  font-size: 25px;
  margin-left: 16px !important;
}

.edit-create-input {
  margin: 16px 4px !important;
  // width: 350px;
  width: 90%;
}

.edit-select-input {
  display: inline-block;
}

.adjust-size-input {
  display: contents;
  // width: fit-content;
}

.full-size-input {
  width: 100%;
}

.edit-create-btn {
  margin: 16px 4px !important;
}

.title-end {
  justify-content: flex-end;
}

.brand-id-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.brand {
  width: 100%;
  justify-content: end;
}

.aside-right {
  margin-top: 16px;
}

.line {
  display: inline-block;
  width: 100%;
  border-top: 0.2px dotted $blackColor;
  margin-top: 16px;
}

.title {
  font-size: 18px;
  margin: 0 0 16px 0;
  font-weight: bold;
}

.sub-title {
  display: block;
  font-size: 18px;
  margin: 16px 0;
  font-weight: bold;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(26%) sepia(82%) saturate(1283%) hue-rotate(212deg) brightness(90%) contrast(87%);
}

input::-webkit-calendar-picker-indicator:hover {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 4px rgba(18, 18, 18, 0.04);
  box-shadow: 0 0 0 4px rgba(18, 18, 18, 0.04);
}

.filter-input {
  margin: 16px 4px !important;
  width: 300px;
}

.sub-sub-title {
  color: rgba(18, 18, 18, 0.87);
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 10px !important;
}

.flex-space-between-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.upload-image-toolbar {
  display: grid;
  grid-template-columns: min-content auto min-content;
  justify-content: space-between;
  grid-template-areas: 'A B C';

  .guide {
    grid-area: A;
  }

  .title {
    grid-area: B;
  }

  .delete {
    grid-area: C;
  }
}

.vertical-menu-sub-menu-title-disable {
  background-color: $whiteColor;
  color: $blackColor;
  display: flex;
  text-align: center;
  align-items: center;
  padding: 12px;
  text-decoration: none;
}

.card-sub-button-wrapper > .card-sub-button-wrapper-btn {
  margin-right: 20px;
}

.passcode {
  font-size: 20px;
  color: $darkErrorColor;
  margin-left: 10px;
  margin-right: 10px;
}

.dialog-remarks {
  color: $errorColor;
}

.flex-1 {
  flex: 1;
}

.split-view {
  // height: 610px;
  height: 750px;
}

.chip-input-wrapper {
  margin-bottom: 15px;

  .tag-display-label {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: rgba(18, 18, 18, 0.54);
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1275px) {
  .edit-create-input {
    width: 30%;
  }

  .aside-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .aside-right {
    flex-basis: 80%;
    margin: 0 16px 0 0;
  }
}

@media screen and (min-width: 960px) and (max-width: 1276px) {
  .edit-create-input {
    width: 90%;
  }

  .aside-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .aside-right {
    flex-basis: 80%;
    margin: 0 16px 0 0;
  }
}

.app-shop-password {
  padding-right: 20px;
  font-weight: 700;
}
