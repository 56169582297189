@import "./globalVariables.scss";

.input {
  margin-right: 8px !important;
  width: 100% !important;
}

.form {
  display: block;
}

.restaurant-brand-add-btn {
  background-color: transparent !important;
  margin: 0 !important;
  padding: 0 !important;
  position: initial !important;
  margin-left: 3% !important;
}

.restaurant-brand-form {
  display: block;
}

.brand-input {
  margin-right: 8px !important;
  width: 100% !important;
}

.restaurant-brand-list-card{
  margin-bottom: 1em;
  width: auto;
}

.restaurant-brand-list-cancelBtn{
  float: right;
}

.restaurant-brand-list-card-simple-list{
  height: 100%; 
  display: flex;
  flex-direction: column;
  border: 1px solid $lightGrayColor;
  box-shadow: 5px 5px $lightGrayColor;
}

.restaurant-brand-list-card-simple-list-span{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.restaurant-brand-list-card-simple-list-span-tcname{
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.restaurant-brand-list-card-simple-list-span-btn{
  margin-top: 5px;
}

.restaurant-brand-list-card-simple-list-btn{
  float: right;
  border-radius: 4px;
  background-color: $subItemColor;
  border: none;
  color: $whiteColor;
  padding: 5px 22px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}

.restaurant-brand-list-card-simple-list-text{
  font-size: 12px;
}

.restaurant-brand-card{
  margin-bottom: 1em;
  width: 100%;
}

.restaurant-brand-card-wrapper{
  padding: 16px;
}

.restaurant-brand-get-wrapper{
  display: initial;
  width: 100%;
}

.restaurant-brand-get-title{
  display: flex;
  flex-direction: column;
}

.restaurant-brand-get-input{
  display: flex;
}

.restaurant-brand-get-customize{
  margin-left: auto;
  margin-top: auto;
}

.cancel-btn{
  padding: 6px 16px !important;
  margin-left: 8px !important;
}

@media screen and (min-width: 1275px) {
  .input {
    width: 30% !important;
  }

  .selected-input {
    width: 30% !important;
  }

  .form {
    display: flex;
  }
}

@media screen and (min-width: 960px) and (max-width: 1276px) {
  .input {
    width: auto !important;
  }

  .selected-input {
    width: 30% !important;
  }

  .form {
    display: flex;
  }
}
