@import "./globalVariables.scss";

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}

.title{
  display: flex;
  justify-content: space-between;
}

.import-btn{
  width: 30% !important;
}

