@import "./globalVariables.scss";

.edit-input{
  margin: 4px !important;
}

.print-template-wrapper-card{
  display: flex;
  justify-content: space-between;
}

.center-item{
  align-self: center;
}

.type-3-add-btn{
  margin: 4.5px 5px 0 0 !important;
}

.text-type-full-width{
  width: 100%;
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 4px;
  .sub-item {
    font-family: monospace;
    width: fit-content;
    padding: 6px 16px;
    margin: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgba(25, 0, 255, 0.5), 
    0px 1px 1px 0px rgba(25, 0, 255,0.14), 0px 1px 3px 0px rgba(25, 0, 255,0.12);
    background-color: rgba(25, 0, 255, 0.1);
    cursor: pointer;
    &:hover {
      background-color: thistle;
      color: $blackColor;
      .sub-product-name,
      .sub-item-name { 
        color: $blackColor !important;
      }
    }
    &.no-hover{
      cursor:auto;
      &:hover {
        background-color: rgba(25, 0, 255, 0.1);
        color: $blackColor;
        .sub-product-name,
        .sub-item-name { 
          color: $blackColor !important;
        }
      }
    }
    .sub-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .sub-item-name {
      color: $blackColor;
    }
    .multiply-icon {
      margin: 0px 5px;
    }
    .quantity {
      font-weight: bold;
    }
    .queue-template-button-on-top {
      display: flex;
      align-items: center;
      padding: 0px 5px;
      color: $subItemColor;
      &:hover {
        color: $lightBlueColor;
      }
    }
  }
  .sub-item-add-button{
    padding: 5px 10px;
    margin: 5px 5px 0 0;
  }
}

.date-format-bottom {
  display: inline-flex;
  margin: 4px;
  width: 100%;
  .sub-item {
    width: fit-content;
    padding: 5px 10px;
    margin: 5px 5px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 2px 1px -1px rgba(25, 0, 255, 0.5), 
    0px 1px 1px 0px rgba(25, 0, 255,0.14), 0px 1px 3px 0px rgba(25, 0, 255,0.12);
    background-color: rgba(25, 0, 255, 0.1);
    width: 100%;
    cursor: pointer;
    &:hover {
      background-color: thistle;
      color: $blackColor;
      .sub-product-name,
      .sub-item-name { 
        color: $blackColor !important;
      }
    }
    &.no-hover{
      cursor:auto;
      &:hover {
        background-color: rgba(25, 0, 255, 0.1);
        color: $blackColor;
        .sub-product-name,
        .sub-item-name { 
          color: $blackColor !important;
        }
      }
    }
    .sub-content {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .sub-item-name {
      color: $blackColor;
      width: 100%;
    }
    .multiply-icon {
      margin: 0px 5px;
    }
    .quantity {
      font-weight: bold;
    }
    .queue-template-button-on-top {
      display: flex;
      align-items: center;
      padding: 0px 5px;
      color: $subItemColor;
      &:hover {
        color: $lightBlueColor;
      }
    }
  }
  .sub-item-add-button{
    padding: 5px 10px;
    margin: 5px 5px 0 0;
  }
}

.date-formate-title{
  font-size: 20px;
  font-weight: bold;
  margin: 4px 8px;
}

.date-formate-card{
  display: flex;
  .left{
    width: 5%;
    display: flex;
    align-self: center;
  }
  .right{
    width: 95%;
  }
}