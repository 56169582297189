@import "./globalVariables.scss";

.url-map-title {
  @include display-flex(space-between, center, row);
  margin-bottom: 8px
}
.url-map-wrapper {
  @include display-flex(center, center, column);
}
.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}

.full-width {
  width: 100% !important;
}

.input {
  margin-right: 8px !important;
  width: 100% !important;
}

.m-input {
  width: 100%;
  margin-bottom: 27px !important;
}

.form {
  display: block;
}

.brandGroupIDOuter{
  display: block;
}

.edit-actions-last-modified{
  display: initial;
  margin-left: 5px;
}

.restaurant-aside-wrapper{
  width: 300px;
  margin-left: 1em;
}

.restaurant-aside-qrCode-card{
  margin-bottom: 1em;
  padding: 1em;
}

.restaurant-aside-qrCode{
  word-break: break-all;
}

.restaurant-aside-map-card{
  margin-bottom: 1em;
  padding: 1em;
}

.restaurant-edit-wrapper{
  display: initial;
  width: 100%;
}

.restaurant-edit-title{
  display: flex;
  flex-direction: column;
}

.restaurant-edit-customize{
  margin-left: auto;
  margin-top: auto;
}

.restaurant-edit-input-none{
  display: none !important;
}

.restaurant-tool-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.restaurant-clear-redis-cache-btn{
  background-color: $otherBtnColor !important;
  color: $whiteColor !important;
}

.restaurant-image-list{
  display: flex;
}

.restaurant-image{
  width: 15%;
  margin-right: 8px;
}

.brandInput{
  margin-right: 8px !important;
  width: 50% !important;
}

.brandCodeOuter{
  display: flex;
}

.brandCodeInner{
  margin-bottom: 27px !important;
  width: 50% !important;
}

.download-text{
  margin: 4px !important;
}

.queueSteps{
  color: rgba(18, 18, 18, 0.87);
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 10px !important;
}

.sub-sub-title{
  color: rgba(18, 18, 18, 0.87);
  font-size: 14px !important;
  font-weight: normal !important;
  margin-left: 10px !important;
}

@media screen and (min-width: 1275px) {
  .input {
    width: 30% !important;
  }

  .form {
    display: flex;
  }

  .brandInput{
    width: 30% !important;
  }

  .brandGroupIDOuter{
    display: flex;
  }

  .brandGroupIDInner{
    width: 49%;
  }

  .brandCodeInner{
    width: 15% !important;
  }

  .district-id-inner{
    width: 49%;
  }

}

@media screen and (min-width: 960px) and (max-width: 1276px) {
  .input {
    width: auto !important;
  }

  .brandInput{
    width: auto !important;
  }

  .selected-input {
    width: 30% !important;
  }

  .form {
    display: flex;
  }

  .brandGroupIDOuter{
    display: flex;
  }

  .brandGroupIDInner{
    width: 49%;
  }

  .brandCodeInner{
    width: 30% !important;
  }

  .district-id-inner{
    width: 49%;
  }

}
