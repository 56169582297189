.all {
  height: 90%;
  width: 90%;
}

.modify_btn_popup {
  margin-top: 5px;
}

.hidden {
  visibility: hidden;
}

.form-wrapper_modal {
  width: 100% !important;
}

.input_modal {
  margin-right: 8px !important;
  width: 110%;
}

@media screen and (min-width: 1275px) {
  .input_modal {
    width: 30% !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1276px) {
  /* .input_modal {
    width: 50% !important;
  } */
}
