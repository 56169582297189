@import "./globalVariables.scss";

.queue-template-content-wrapper {
  width: calc(100% - 32px) !important;
  overflow: auto !important;
  height: 100%;
  // padding: 16px;
  // height: calc(100% - 32px);
}

.queue-template-flex-right {
  // flex: 6;
  flex-grow: 1;
  height: calc(100% - 50px);
  // height: 75vh;
  padding: 5px 0px;
  overflow: auto !important;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.queue-template-content-content {
  width: 100% !important;
  overflow: auto !important;
  height: calc(100% - 100px);
  background: $whiteColor;

  &.redeem-group-content {
    width: calc(100% - 48px) !important;
    padding: 24px;
  }

  .RaListToolbar-toolbar-42 {
    align-items: initial;
  }

  .top-tool-bar {
    padding-top: 0px;
  }

  .category-filter {
    flex-wrap: initial;
    min-height: initial;
    margin-top: initial;
  }

  .RaListToolbar-actions-43,
  .RaListToolbar-actions-60 {
    padding: 0px;
    align-items: initial;
  }
}

.queue-template-array-list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  &.no-flex {
    display: block;
  }

  .time-section-button {
    display: flex;
  }
}

.queue-template-button-on-top {
  z-index: 1 !important;
}

.queue-template-tab-background {
  background-color: $whiteColor;
}

.queue-template-error {
  border: 5px solid $errorColor;
  padding: 5px !important;
}

.queue-template-selected-product {
  background-color: lightyellow !important;
}

.queue-template-selected-product-A {
  background-color: $tableTypeAColor;
}

.queue-template-selected-product-B {
  background-color: $tableTypeBColor;
}

.queue-template-selected-product-C {
  background-color: $tableTypeCColor;
}

.queue-template-selected-product-D {
  background-color: $tableTypeDColor;
}

.queue-template-selected-product-E {
  background-color: $tableTypeEColor;
}

.queue-template-selected-product-F {
  background-color: $tableTypeFColor;
}

.error-message-dark-red {
  display: flex;
  align-items: center;
  color: $darkErrorColor;
  font-size: 12px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.queue-template-list-item {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(18, 18, 18, 0.2),
    0px 1px 1px 0px rgba(18, 18, 18, 0.14), 0px 1px 3px 0px rgba(18, 18, 18, 0.12);
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  flex-wrap: wrap;

  &:hover {
    background-color: lightyellow;
  }

  &.no-hover {
    &:hover {
      background-color: transparent;
    }
  }

  .code {
    // margin-right: 20px;
    font-size: 12px;
    color: $grayColor;

    .type {
      color: lightsalmon;
    }
  }

  .code-with-warning {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  .sub-code {
    font-size: 12px;

    .sub-type {
      color: lightsalmon;
    }

    .table-type-label {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      font-weight: 700;
      border-radius: 5px;
      width: fit-content;
      height: fit-content;
    }

    .sub-type-selected {
      color: $blackColor;
    }

    .sub-type-A {
      color: $tableTypeAColor;
    }

    .sub-type-B {
      color: $tableTypeBColor;
    }

    .sub-type-C {
      color: $tableTypeCColor;
    }

    .sub-type-D {
      color: $tableTypeDColor;
    }

    .sub-type-E {
      color: $tableTypeEColor;
    }

    .sub-type-F {
      color: $tableTypeFColor;
    }
  }

  .content-icon {
    display: flex;
    align-items: center;
    width: 10%;
  }

  .content-info {
    width: 90%;
  }

  .content {
    width: 100%;

    .top {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      .content-information {
        max-width: 90%;

        .hint-clip-text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: rgba(25, 0, 255, 0.5);
          font-size: 18px;
          margin-left: 4px;
          font-weight: normal;
        }
      }

      .name {
        font-size: 24px;
        font-weight: bold;
      }

      .price {
        font-size: 24px;
        margin-left: auto;
        color: deeppink;
      }

      .existing {
        color: rgba(25, 0, 255, 0.5);
        font-size: 24px;
        margin-left: 2px;
      }

      .hint {
        color: rgba(25, 0, 255, 0.5);
        font-size: 18px;
        margin-left: 4px;
        font-weight: normal;
        display: inline;
      }

      .delete-button {
        margin-left: auto;
      }

      .delete-button-margin {
        margin-right: 8px;
      }
    }

    .bottom {
      width: calc(100%-20px);
      display: grid;
      grid-row: 1fr;

      &.drag-and-drop-section {
        padding: 20px 0px;
      }

      .grouped-section {
        background-color: lightcyan;
        padding: 10px 10px 30px;
        border-radius: 10px;
        display: grid;
        grid-row: 1fr;
      }

      .sub-item {
        font-family: monospace;
        width: fit-content;
        padding: 8px 10px;
        margin: 5px 5px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px 1px rgba(0, 0, 0, 0.12);
        background-color: rgba(25, 0, 255, 0.1);
        cursor: pointer;

        &.queue-table-type {
          background-color: white;
        }

        .sub-item-name {
          &.table-type {
            color: white;
            background-color: #1976d2;
            padding: 5px;
            border-radius: 5px;
            margin-bottom: 5px;
          }
        }

        &:hover {
          background-color: thistle;
          color: $blackColor;

          .sub-product-name,
          .sub-item-name {
            color: $blackColor !important;
            &.table-type {
              color: white !important;
              background-color: #3f51b5;
            }
          }
        }

        &.queue-template-selected-product {
          background-color: lightpink !important;
        }

        &.no-hover {
          cursor: auto;

          &:hover {
            background-color: rgba(25, 0, 255, 0.1);
            color: $blackColor;

            .sub-product-name,
            .sub-item-name {
              color: $blackColor !important;
              &.table-type {
                color: white !important;
                background-color: #3f51b5;
              }
            }
          }
        }

        .sub-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }

        .sub-item-name {
          color: $blackColor;
        }

        .multiply-icon {
          margin: 0px 5px;
        }

        .quantity {
          font-weight: bold;
        }

        .background-color {
          height: 20px;
          width: 20px;
          border-radius: 100%;
          position: relative;
          flex-shrink: 0;
        }

        .foreground-color {
          height: 12px;
          width: 12px;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        .queue-template-button-on-top {
          display: flex;
          align-items: center;
          padding: 0px 5px;
          color: $subItemColor;

          &:hover {
            color: $lightBlueColor;
          }
        }
      }

      .sub-item-add-button {
        padding: 5px 10px;
        margin: 5px 5px 0 0;
      }
    }
  }

  .ticket-type-section {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    width: fit-content;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px 1px rgba(0, 0, 0, 0.12);
  }
}