$themeColor: #17468f;
$appBarColor: #3f51b5;
$disableColor: #4a4a4a;
$hoverColor: #0064ff;
$subItemColor: #3f51b5;
$lightBlueColor: lightblue;
$aliceblueColor: aliceblue;
$tipsColor: orange;
$errorColor: red;
$darkErrorColor: darkRed;
$otherBtnColor: #dc004e;
$otherBtnHoverColor: #9a0036;
$desktopMinWidth: 720px;
$imageWrapperBackground: mintcream;

$blackColor: #121212;
$whiteColor: #fff;
$grayColor: gray;
$lightGrayColor: #ccc;
$middleGrayColor: #c0c0c0;

$grayColors: (
  grayTextOnWhite: #575757,
  grayTextOnBlack: #ababab,
  darkGrayTextOnGray: #58595b,
  lightGrayElementOnWhite: #d0d2d3,
  lightGrayElementOnBlack: #a0a0a0,
  midGrayElementOnBlack: #707070,
  darkGrayElementOnBlack: #535353,
  filterBorderGray: #333333,
  imageBorder: #e2e2e2,
  subItemOnBlack: #aeaeae,
  modifierOnBlack: #7e7e7e,
);

$tableTypeAColor: #f85999;
$tableTypeBColor: #6cbb20;
$tableTypeCColor: #07a5f7;
$tableTypeDColor: #e69201;
$tableTypeEColor: #ca61fb;
$tableTypeFColor: #d7d221;

$tableTypeADarkColor: #32121f;
$tableTypeBDarkColor: #162506;
$tableTypeCDarkColor: #012131;
$tableTypeDDarkColor: #2e1d00;
$tableTypeEDarkColor: #281332;
$tableTypeFDarkColor: #2b2a07;

@mixin display-flex($justify, $align, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin flex($justify, $align) {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: $justify;
  justify-content: $justify;
  -webkit-align-items: $align;
  align-items: $align;
}

:export {
  whiteColor: $whiteColor;
  blackColor: $blackColor;
  grayColor: $grayColor;
  lightGrayColor: $lightGrayColor;
  subItemColor: $subItemColor;
  errorColor: $errorColor;
  middleGrayColor: $middleGrayColor;
}
